import type { MetaFunction } from '@remix-run/node';
import { Links, LiveReload, Meta, Outlet, Scripts, ScrollRestoration } from '@remix-run/react';
import { MantineProvider, createEmotionCache, ColorSchemeProvider } from '@mantine/core';
// import { NotificationsProvider } from '@mantine/notifications';
import { StylesPlaceholder } from '@mantine/remix';
import React from 'react';
import { ModalsProvider } from '@mantine/modals';
// import { theme } from './theme';

export const meta: MetaFunction = () => ({
  charset: 'utf-8',
  title: 'New Remix App',
  viewport: 'width=device-width,initial-scale=1',
});

createEmotionCache({ key: 'mantine' });

export default function App() {
  return (
    <ColorSchemeProvider colorScheme={'dark'} toggleColorScheme={() => { }}>
      <MantineProvider theme={{ colorScheme: 'dark' }} withGlobalStyles withNormalizeCSS>
        {/* <NotificationsProvider> */}
        <ModalsProvider>
          <html lang="en" style={{ height: '100%' }}>
            <head>
              <StylesPlaceholder />
              <Meta />
              <Links />
            </head>
            <body style={{ height: '100%' }}>
              <Outlet />
              <ScrollRestoration />
              <Scripts />
              <LiveReload />
            </body>
          </html>
        </ModalsProvider>
        {/* </NotificationsProvider> */}
      </MantineProvider>
    </ColorSchemeProvider >
  );
}